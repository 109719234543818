<template>
    <div class="login">
        <div class="centerWidth marginAuto loginContent">
            <p class="loginContentDL">新医云学苑登录</p>
            <img src="@/assets/img/do_login/login_text.png" class="loginTextImg" alt="">
            <div class="loginBox">
                <div class="loginBoxTab flex spacebetween alignCenter">
                    <div :class="loginBoxTab == 1 ? 'bottomBorder1' : ''" class="loginBoxTabs c33 cursorP" @click="changeLoginType(1)">个人登录</div>
                    <div class="loginBoxTabsborder"></div>
                    <div :class="loginBoxTab == 2 ? 'bottomBorder1' : ''" class="loginBoxTabs c33 cursorP" @click="changeLoginType(2)">机构登录</div>
                </div>
                
                <div v-show="!wechatLo" class="loginBoxInput">
                    <!-- el-icon-lock -->
                    <el-input
                        style="width: 307px;"
                        placeholder="输入账号"
                        prefix-icon="iconfont icon-user"
                        v-model="user.phone">
                    </el-input>
                    <el-input v-show="loginType == 1" style="width: 307px;margin-top:17px;" prefix-icon="iconfont icon-lock" placeholder="输入密码" v-model="user.password" show-password @keyup.enter.native="doLogin"></el-input>
                    <el-input v-show="loginType == 2" style="width: 307px;margin-top:17px;" placeholder="输入验证码" v-model="user.code" prefix-icon="iconfont icon-lock" class="input-with-select" @keyup.enter.native="doLogin">
                        <el-button slot="append" @click="sendCode">{{getCode}}</el-button>
                    </el-input>
                    <button class="doLogin cursorP" @click="doLogin">登录</button>
                </div>
                <div v-show="!wechatLo" class="loginBoxEvent marginAuto flex spacebetween">
                    <span v-show="loginType == 1" class="loginBoxEventYZM cursorP" @click="loginType = 2">验证码登录</span>
                    <span v-show="loginType == 2" class="loginBoxEventYZM cursorP" @click="loginType = 1">密码登录</span>
                    <div class="loginBoxEventRgisterANDForget">
                        <span class="cursorP" @click="goother({path:'/register',query:{type:1,registerType: loginBoxTab}})">立即注册</span>
                        <span class="loginBoxEventRgisterANDForgetCenter"></span>
                        <span class="cursorP" @click="goother({path:'/register',query:{type:2}})">忘记密码？</span>
                    </div>
                </div>
                <wxlogin v-show="wechatLo" appid="wxd262391951f4d78b" :scope="'snsapi_login'" :theme="'black'" :redirect_uri='encodeURIComponent(`http://nmep.com.cn/#/?logtype=${loginBoxTab}`)' rel="external nofollow"></wxlogin>
                <div v-show="wechatLo" class="cursorP" @click="wechatLo = false">验证码/密码登录</div>

                <img v-show="!wechatLo" src="@/assets/img/do_login/WXlogin.png" class="WXlogin marginAuto cursorP" @click="wechatLo = true" alt="">
            </div>
        </div>
    </div>
</template>
<script>
import { sms, password_login } from '@/utils/Api/doLogin'
import { get_user_unique } from '@/utils/Api/commonList'
import wxlogin from 'vue-wxlogin';
export default {
    name: 'login',
    data(){
        return{
            loginBoxTab: 1,             // 用户类型 1 个人 2 机构
            user:{
                phone:'',
                code:'',
                password:''
            },
            getCode: '获取验证码',
            times: 60,
            codeTimer: null,
            loginType: 2,               // 登录类型  1 密码 2 验证码
            wechatLo: false
        }
    },
    components:{wxlogin},
    created(){
        // this.loginBoxTab = this.$route.query.userType == 2 ? 2 : 1
    },
    methods:{
        wechatLogin(){
            // get_user_unique({})
        },
        changeLoginType(val){
            this.loginBoxTab = val;
            this.user.password = '';
            this.user.code = '';
            this.user.phone = '';
        },
        async doLogin(){
            let params = {
                ...this.user,
                type: this.loginType,
                user_type: this.loginBoxTab
            }
            try {
                let token = await password_login(params)
                if(token){
                    this.$message.success('登录成功！')
                    this.$store.dispatch('user/set_token', token.token)
                    setTimeout(() => {
                        if(this.$route.query.redirect){
                            this.goother({path: this.$route.query.redirect})
                            return
                        }
                        this.goother({path:'/'})
                    },1000)
                }
            }catch(e){console.log(e)}
        },
        async sendCode(){
            if(!this.user.phone){
                this.$message.warning('请填写手机号码')
                return
            }
            if(!(/^(1[0-9])\d{9}$/.test(this.user.phone))){
                this.$message.warning('手机号填写不正确')
                return
            }
            if(this.codeTimer){
                this.$message.warning(`请在${this.times}后重新发送！`)
                return
            }
            try{
                await sms({phone: this.user.phone})
                this.$message.success('验证码发送成功！')
            }catch(e){console.log(e)}

            this.codeTimer = setInterval(() => {
                this.times--
                this.getCode = `${this.times}后重新发送`
                if(this.times < 1){
                    clearInterval(this.codeTimer)
                    this.codeTimer = null
                    this.getCode = '获取验证码'
                    this.times = 60
                }
            },1000)
        }
    }
}
</script>
<style lang="scss" scoped>
.WXlogin{
    width: 211px;
    height: 50px;
    margin-top: 36px;
}
.login{
    width: 100%;
    height: 564px;
    background: url('../../../assets/img/do_login/login_bg.png');
    .loginContent{
        position: relative;
        .loginBox{
            width: 358px;
            height: 500px;
            background: #fff;
            position: absolute;
            top: 56px;
            right: 135px;
            border-radius: 8px;
            .loginBoxEvent{
                width: 307px;
                margin-top: 18px;
                font-size: 14px;
                .loginBoxEventYZM{color:#47D7E3;}
                .loginBoxEventRgisterANDForget{
                    color: #949898;
                    .loginBoxEventRgisterANDForgetCenter{
                        width: 1px;
                        height: 11px;
                        background: #949898;
                        display: inline-block;
                        margin-left: 10px;
                        margin-right: 10px;
                    }
                }
            }
            .loginBoxInput{
                margin-top: 40px;
                .doLogin{
                    margin-top: 24px;
                    width: 307px;
                    height: 53px;
                    border-radius: 8px;
                    background: #47D7E3;
                    color: #fff;
                    font-size: 16px;
                }
            }
            .loginBoxTab{
                height: 57px;
                border: 1px solid #dddddd;
                .loginBoxTabsborder{
                    width: 0px;
                    height: 100%;
                    opacity: 1;
                    border: 1px solid #dddddd;
                }
                .loginBoxTabs{
                    font-size: 16px;
                    flex: 1;
                }
            }
        }
        .loginTextImg{
            position: absolute;
            left: 0;
            top: 222px;
            width: 463px;
            height: 161px;
        }
        .loginContentDL{
            font-size: 33px;
            color: #fff;
            left: 30px;
            top: 114px;
            position: absolute;
            border-left: 3px solid #fff;
            padding-left: 24px;
            line-height: 36px;
        }
    }
}
</style>